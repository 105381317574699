<template>
    <div class="d-inline-block">
        <div class="select">
            <select id="entry-status-select" class="plain-select" v-model="status_to_update" @change="changeStatus()">
                <option :selected="order_status" v-for="(value, key) in statuses" :value="key" v-text="value"></option>
            </select>
        </div>

        <modal v-if="modalNeedsMoreInfo" @close="modalNeedsMoreInfo = false">
            <h3 slot="header">Needs More Information</h3>
            <div class="content" slot="body">
                <textarea v-model="needs_more_info_text" placeholder="Enter the message that will be emailed to the Submitter."></textarea>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn btn-transparent" @click="closeModal()">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="sendNeedsMoreInfo()">Send Email</button>
            </div>
        </modal>

    </div>
</template>

<script>
    import modal from './Modal.vue';

    export default {
        components: {
            modal
        },
        props: {
            orderStatus: {
                type: String,
                required: true
            },
            actionUrl: {
                type: String,
                required: true
            },
            statuses: {
                type: Array,
                required: true
            },
            statusUpdateType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                status_to_update: this.orderStatus,
                modalNeedsMoreInfo: false,
                needs_more_info_text: '',
            }
        },
        methods: {
            changeStatus: function() {
                if (this.status_to_update === 'need_more_info') {
                    this.modalNeedsMoreInfo = true;
                    return;
                }

                window.axios
                    .put(this.actionUrl, {
                        status: this.status_to_update
                    })
                    .then((response) => {
                        let dataBack = response.data;
                        location.reload();
                    });
            },
            sendNeedsMoreInfo: function() {
                window.axios
                    .put(this.actionUrl, {
                        status: this.status_to_update,
                        needs_more_info_text: this.needs_more_info_text,
                    })
                    .then((response) => {
                        let dataBack = response.data;
                        location.reload();
                    });
            },
            closeModal: function() {
                this.status_to_update = this.orderStatus;
                this.modalNeedsMoreInfo = false;
            }
        }
    }

</script>
