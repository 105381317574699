<template>
    <div>
        <div class="form-not-modals order-network-form-wrapper">
            <div class="col-12 border-bottom mb-4 pl-0 top-header-sticky">
                <div class="d-flex justify-content-between align-items-end pb-1">
                    <h1 class="mb-0">FYC Network Order</h1>
                    <div class="entry-info-number">
                        <div class="entry-id-wrapper d-flex align-items-end">
                            <div class="mr-4">
                                <label class="membership-type">Order Status</label>
                                <div class="membership-label" :class="orderinfo.order_status == 'submitted' ? 'submitted-color' : ''">
                                    <template v-if="role == 'admin'">
                                        {{ submission_statuses[orderinfo.order_status] }}
                                    </template>
                                    <template v-else>
                                        {{ submission_statuses_submitter.find(x => x.id === orderinfo.order_status).text }}
                                    </template>
                                </div>
                            </div>
                            <div>
                                <span class="label small-caps">FYC No.</span>
                                <span class="value" id="entryId">{{ orderinfo.order_id }}</span>
                            </div>
                            <div class="form-actions form-actions-edit" v-if="role == 'admin'">
                                <a href="#" @click.prevent="updateForm('','',true)" class="btn btn-primary btn-icon" id="SaveButton" v-if="role == 'admin'">
                                    <save-icon></save-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 mt-0 event-order-steps-menu d-flex justify-content-between align-items-start" v-if="role == 'admin'">
                <admin-statuses
                    type="order_network"
                    :actionurl_assets="actionurl_assets"
                    :actionurl_details="actionurl_details"
                    :actionurl_send_at_time="actionurl_send_at_time"
                    :actionurl_team_assigned="actionurl_team_assigned"
                    :asset_statuses="asset_statuses"
                    :invoice_payment_edit="invoice_payment_edit"
                    :invoice_show="invoice_show"
                    :order="orderinfo"
                    :submission_statuses="submission_statuses"
                    :team_assigned_members="team_assigned_members"
                    :updateurl="actionurl"
                    :user_change_url="user_change_url"
                    :user_suggestions_url="user_suggestions_url"
                ></admin-statuses>
            </div>

            <ul class="accordion-tabs mb-5" v-if="role == 'admin'">
                <li class="nav-item d-inline-block">
                    <a class="tab-link is-active" :href="'/order/'+ orderinfo.order_id">Order Details</a>
                </li>
                <li class="nav-item d-inline-block">
                    <a class="tab-link" :href="'/order/'+ orderinfo.order_id + '/logs'">Change Log</a>
                </li>
                <li class="nav-item d-inline-block">
                    <a class="tab-link" :href="'/order/'+ orderinfo.order_id + '/consolidation'">Content Consolidation</a>
                </li>
            </ul>

            <div class="accordion-item accordion-contact-details-wrapper mb-3" id="order-step-0">
                <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between" id="order-step--1">
                    <a @click.prevent="applicationStepSelector(0)" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2" :class="dynamicClass(0)">
                        <div class="position-absolute app-status-icons">
                            <Attention svg-class="svg-24 red-svg" v-if="iconArray.details && iconArray.details == 'incomplete'"></Attention>
                            <checkmark svg-class="svg-24 svg-green" v-if="iconArray.details && iconArray.details == 'complete'"></checkmark>
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                            <h3 class="mb-0">Order Details</h3>
                            <div class="accordion-status-wrapper d-flex align-items-center w-25" :class="statusForIndicators.includes(orderinfo.order_status) ? 'justify-content-between' : 'justify-content-end'">
                                <div class="d-flex align-items-center cursor-initial" :class="'pr-2 status-color-' + orderinfo.order_status" v-if="statusForIndicators.includes(orderinfo.order_status)">
                                    <submitted svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'submitted'"></submitted>
                                    <question svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'need_more_info'"></question>
                                    <circle_check svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'approved' || orderinfo.order_status == 'live' || orderinfo.order_status == 'live_checked' || orderinfo.order_status == 'approved_for_build'"></circle_check>
                                    <template v-if="role == 'admin'">
                                        <span class="text-uppercase">{{ submission_statuses[orderinfo.order_status] }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="text-uppercase">{{ submission_statuses_submitter.find(x => x.id === orderinfo.order_status).text }}</span>
                                    </template>
                                </div>
                                <up-chevron-icon class="svg-accent" :class="(orderinfo.application_step == 0) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                            </div>
                        </div>
                    </a>
                </div>
                <div id="accordion-order-details" class="pl-30 pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="(orderinfo.application_step == null || orderinfo.application_step == 0) ? 'show' : ''">
                        <div class="mt-5 full-instructions-wrapper fields-wrapper-row">
                            <h3 class="order-header">Submitter Contact</h3>
                                <div class="form-group-beside row" :class="errorObject['details']['your_name'] ? 'required' : ''">
                                    <label for="your_name" class="col-2 col-md-6">Your Name: <em class="yellow no-style">*</em></label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','your_name',false)" type="text" v-model="orderinfo.your_name" name="your_name" id="your_name" class="form-control your_name" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                                <div class="form-group-beside row" :class="errorObject['details']['your_email'] ? 'required' : ''">
                                    <label for="your_email" class="col-2 col-md-6">
                                        Your Email: <em class="yellow no-style">*</em>
                                        <div class="description pl-4 mt-1" v-if="errorObject['details'] && errorObject['details']['your_email']"><p class="red-font">{{ errorObject['details']['your_email'][0] }}</p></div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','your_email',false)" type="email" v-model="orderinfo.your_email" id="your_email" class="form-control your_email" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                                <div class="form-group-beside row" :class="errorObject['details']['your_phone'] ? 'required' : ''">
                                    <label for="your_phone" class="col-2 col-md-6">Your Phone: <em class="yellow no-style">*</em></label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','your_phone',false)" type="text" v-model="orderinfo.your_phone" id="your_phone" class="form-control your_phone" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>

                            <h3 class="order-header mt-5">FYC Site Contact Information</h3>
                            <p>Technical contact for FYC site troubleshooting</p>
                                <div class="form-group-beside row" :class="errorObject['details']['uploader_name'] ? 'required' : ''">
                                    <label for="uploader_name" class="col-2 col-md-6">Contact Name: <em class="yellow no-style">*</em></label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','uploader_name',false)" type="text" v-model="orderinfo.uploader_name" name="uploader_name" id="uploader_name" class="form-control uploader_name" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                                <div class="form-group-beside row" :class="errorObject['details']['uploader_email'] ? 'required' : ''">
                                    <label for="uploader_email" class="col-2 col-md-6">
                                        Contact Email: <em class="yellow no-style">*</em>
                                        <div class="description pl-4 mt-1" v-if="errorObject['details'] && errorObject['details']['uploader_email']"><p class="red-font">{{ errorObject['details']['uploader_email'][0] }}</p></div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','uploader_email',false)" type="email" v-model="orderinfo.uploader_email" id="uploader_email" class="form-control uploader_email" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                                <div class="form-group-beside row" :class="errorObject['details']['uploader_phone'] ? 'required' : ''">
                                    <label for="uploader_phone" class="col-2 col-md-6">Contact Phone: <em class="yellow no-style">*</em></label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','uploader_phone',false)" type="text" v-model="orderinfo.uploader_phone" id="uploader_phone" class="form-control uploader_phone" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                        </div>

                        <div class="full-instructions-wrapper fields-wrapper-row">
                            <h3 class="order-header">Order Details</h3>
                                <div class="form-group-beside row" :class="errorObject['details']['cable_network'] ? 'required' : ''">
                                    <label :for="'cable_network'" class="col-2 col-md-6">
                                        Broadcast/Cable Network, Studio or Platform: <em class="yellow no-style">*</em>
                                        <div class="description">If not listed or submitting for studio, select other.<br/>
                                            The organization listed here will populate to the top and bottom banner of the invite. Please enter EXACTLY how you would like it to appear. (i.e. Television Academy, rather than TVA) .</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <v-select v-if="update_allowed['details']" :id="'cable_network'" placeholder="Network" v-model="orderinfo.cable_network" :options="cable_networkOptions"  @input="updateForm('details','cable_network',false)" ></v-select>
                                        <span class="readonly pl-2" v-text="orderinfo.cable_network" v-if="!update_allowed['details']"></span>
                                    </div>
                                </div>
                            <div class="form-group-beside row" v-if="orderinfo.cable_network == 'Other'" :class="errorObject['details']['cable_network_other'] ? 'required' : ''">
                                <label for="cable_network_other" class="col-2 col-md-6">If other, list here: </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','cable_network_other',false)" type="text" v-model="orderinfo.cable_network_other" id="cable_network_other" class="form-control cable_network_other" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div class="form-group-beside row" v-if="role == 'admin'" :class="errorObject['details']['cable_network_all_caps'] ? 'required' : ''">
                                <label for="cable_network_all_caps" class="col-2 col-md-6">ALL CAPS version:</label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','cable_network_all_caps',false)" type="text" v-model="orderinfo.cable_network_all_caps" id="cable_network_all_caps" class="form-control cable_network_all_caps" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['cable_network_logo_preview'] ? 'required' : ''">
                                <label for="cable_network_logo_preview" class="col-2 col-md-6">
                                    <div>Broadcast/Cable Network, Studio or Platform Logo: <em class="yellow no-style">*</em></div>
                                    <div class="description">Must be square image, 600x600px minimum, .jpg or .png, not on a transparent background.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <fyc-cropper
                                        v-if="update_allowed['details']"
                                        indexval="1000"
                                        :initval="orderinfo.cable_network_logo_preview"
                                        min-crop-width="600"
                                        min-crop-height="600"
                                        @deleteImage="deleteImage('cable_network_logo_preview')"
                                        @croppedImage="saveForValidation"
                                        :file-size-restriction="true"
                                        machine_name="cable_network_logo_preview"
                                        :updating-form="updatingForm"
                                        :role="role"
                                    >
                                    </fyc-cropper>
                                    <div class="small-img image-available" v-if="!update_allowed['details']">
                                        <img :src="orderinfo.cable_network_logo_preview">
                                    </div>
                                </div>
                            </div>

                                <div class="form-group-beside row" :class="errorObject['details']['url'] ? 'required' : ''">
                                    <label for="url" class="col-2 col-md-6">
                                        URL for your FYC site: <em class="yellow no-style">*</em>
                                        <div class="description">
                                            Must direct members to a dedicated landing page specific to this order. Please include https:// in the URL.
                                        </div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','url',false)" type="text" placeholder="https://example.com"  v-model="orderinfo.url" id="url" class="form-control url" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>

                            <div class="form-group-beside row" :class="errorObject['details']['url_to_display'] ? 'required' : ''">
                                <label for="url_to_display" class="col-2 col-md-6">
                                    URL to display:
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','url_to_display',false)" type="text" placeholder="example.com"  v-model="orderinfo.url_to_display" id="url_to_display" class="form-control url_to_display" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                                <div class="form-group-beside row select-box-bigger" :class="errorObject['details']['platform'] ? 'required' : ''">
                                    <label :for="'platform'" class="col-2 col-md-6">
                                        <div>Platforms where your FYC content is available: <em class="yellow no-style">*</em></div>
                                        <div class="description">Select all that apply</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                      <v-select v-if="update_allowed['details']" :closeOnSelect="false" multiple ref="platform" placeholder="Platform" v-model="orderinfo.platform" :options="platformOptions"  @input="updateForm('details','platform',false)" ></v-select>
                                        <span class="readonly pl-2" v-text="multiselectFormatted(orderinfo.platform)" v-if="!update_allowed['details']"></span>
                                    </div>
                                </div>

                            <div class="form-group-beside row" v-if="orderinfo.platform && orderinfo.platform.includes('Other')" :class="errorObject['details']['platform_other'] ? 'required' : ''">
                                <label for="platform_other" class="col-2 col-md-6">If other, list here: </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','platform_other',false)" type="text" v-model="orderinfo.platform_other" id="platform_other" class="form-control platform_other" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                                <div class="form-calendar form-group-beside row" :class="errorObject['details']['site_live_date'] ? 'required' : ''">
                                    <label for="site_live_date" class="col-2 col-md-6">
                                        <div>Site live date: <em class="yellow no-style">*</em></div>
                                        <div class="description">If your site is currently accessible, use today’s date</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <calendar-icon class="svg-accent"></calendar-icon>
                                        <flat-pickr v-if="update_allowed['details']" id="site_live_date" v-model="orderinfo.site_live_date" :config="config"
                                                    placeholder="Select date" @on-change="siteLiveDateConfigCallback"></flat-pickr>
                                        <span class="readonly-date" v-text="orderinfo.site_live_date" v-if="!update_allowed['details']"></span>
                                    </div>
                                </div>
                                <div class="form-calendar form-group-beside row" :class="errorObject['details']['site_close_date'] ? 'required' : ''">
                                    <label for="site_close_date" class="col-2 col-md-6">
                                        <div>Site close date: <em class="yellow no-style">*</em></div>
                                        <div class="description">Access to academy members must expire no later than September 30.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <calendar-icon class="svg-accent"></calendar-icon>
                                        <flat-pickr v-if="update_allowed['details']" id="site_close_date" v-model="orderinfo.site_close_date" :config="config"
                                                    placeholder="Select date"  @on-change="siteCloseDateConfigCallback"></flat-pickr>
                                        <span class="readonly-date" v-text="orderinfo.site_close_date" v-if="!update_allowed['details']"></span>
                                    </div>
                                </div>

                            <h3 class="order-header border-sep mt-5">Access Codes</h3>

                            <div class="access-code-required">
                                <div class="radio-buttons-toggle-wrapper mb-4" :class="errorObject['details']['is_code_unique'] ? 'required' : ''">
                                    <div class="mt-4 ml-2 f-18">Does your site require unique access codes? <em class="yellow no-style">*</em></div>
                                    <div class="ml-4 pb-2 mt-4 col-12 col-md-9 d-flex align-items-center justify-content-between mt-2">
                                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                                            <input v-if="update_allowed['details']" @change="updateForm('details','is_code_unique',false)" type="radio" v-model="orderinfo.is_code_unique" value="0" class="custom-control-input" id="orderinfo.is_code_unique_no" name="orderinfo.is_code_unique">
                                            <div v-else>
                                                <circle-filled-icon v-if="orderinfo.is_code_unique == '0'"></circle-filled-icon>
                                                <circle_icon v-else></circle_icon>
                                            </div>
                                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.is_code_unique_no">
                                                No - one code will work for all
                                            </label>
                                        </div>
                                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                                            <input v-if="update_allowed['details']" @change="updateForm('details','is_code_unique',false)" type="radio" v-model="orderinfo.is_code_unique" value="1" class="custom-control-input" id="orderinfo.is_code_unique_yes" name="orderinfo.is_code_unique">
                                            <div v-else>
                                                <circle-filled-icon v-if="orderinfo.is_code_unique == '1'"></circle-filled-icon>
                                                <circle_icon v-else></circle_icon>
                                            </div>
                                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.is_code_unique_yes">
                                                Yes - each member will have a unique code
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="" v-show="orderinfo.is_code_unique == '1'">
                                    <div class="form-group-beside" :class="errorObject['details']['access_code_upload'] ? 'required' : ''">
                                        <file-upload
                                            display_image_or_filename="file"
                                            file_types=".csv, .xls, .xlsx"
                                            :entry="orderinfo"
                                            machine_name="access_code_upload"
                                            description="You must upload 30,000 codes. These will be assigned to the Television Academy membership and distributed in your FYC email. File must be in .csv, .xls or .xlsx format."
                                            display_name="Upload Codes:"
                                            required="true"
                                            :saved_filename="orderinfo.access_code_upload"
                                            view_url=""
                                            :update_allowed="update_allowed['details']"
                                            role="role"
                                            :action_url="'/order/network/' + orderinfo.id"
                                            @saveForValidation="fileSaveForValidation">
                                        </file-upload>

                                        <div class="col-10 col-md-6 pl-3 offset-md-6 text-right" v-if="role == 'admin'">
                                            <a :href="orderinfo.access_code_upload_path" v-if="orderinfo.access_code_upload" target="_blank" class="mb-2 btn btn-primary">
                                                <download class="svg-black"></download>
                                                Download
                                            </a>
                                        </div>
                                    </div>

                                    <div class="form-group-beside row" :class="errorObject['details']['network_access_code_test'] ? 'required' : ''">
                                        <label for="network_access_code_test" class="col-2 col-md-6">Test code or password for verification: <em class="yellow no-style">*</em>
                                            <div class="description">
                                                <p>If your site requires an access code, list a test code or password here for Academy verification. If no code or password, write n/a.</p>
                                                <p>A generic/easy to remember viewing code is recommended. For example, [your network/studio’s abbreviation] FYC2024 [XXXFYC2024]</p>
                                                <p>Screening Codes must NOT contain any spaces or any of the following special characters: & # ; : < > – " " = _ </p>
                                            </div>
                                        </label>
                                        <div class="col-10 col-md-6 input-wrapper">
                                            <input @blur="noSpecialCharactersCheck()" type="text" v-model="orderinfo.network_access_code_test" id="network_access_code_test" class="form-control network_access_code_test" :disabled="(role == 'viewer')?true:false">
                                            <div class="red pl-2" v-if="hasSpecialCharacter">Please limit your code to numbers and letters only.</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="orderinfo.is_code_unique == '0'" class="form-group-beside row" :class="errorObject['details']['network_access_code'] ? 'required' : ''">
                                    <label for="network_access_code" class="col-2 col-md-6">
                                        Viewing Platform Code: <em class="yellow no-style">*</em>
                                        <div class="description">Enter the code used for all members - no import file needed.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','network_access_code',false)" type="text" v-model="orderinfo.network_access_code" id="network_access_code" class="form-control event_url" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                            </div>

                            <div class="delete-program-button mt-2 pull-right" v-if="update_allowed['details']">
                                <a v-if="orderinfo.program_info && orderinfo.program_info.length > 1" href="" class="btn btn-primary" @click.prevent="deleteProgramAllowed = !deleteProgramAllowed">
                                    <edit-icon></edit-icon> Remove a Program
                                </a>
                            </div>

                            <h3 class="order-header border-sep mt-5 pb-2" :class="errorObject['details'] && errorObject['details']['program_info'] ? 'required' : ''">List all Emmy-eligible content on your FYC Platform</h3>
                            <div class="program-info-wrapper position-relative" v-for="(program, index) in orderinfo.program_info" :class="(deleteProgramAllowed) ? 'delete-button-showing' : ''">

                                <div class="">
                                    <div v-show="deleteProgramAllowed" @click="deleteProgramModalActivate(program, index)" class="delete-program-block cursor-pointer"><trash-icon></trash-icon> Delete</div>
                                    <input type="hidden" v-model="program.key" :name="'program_name' + index">
                                    <div class="form-group-beside row" :class="errorObject['details']['program_info'] || errorObject['details']['program_info.'+index+'.category'] ? 'required' : ''">
                                        <label :for="'category' + index" class="col-2 col-md-6">Category: <em class="yellow no-style">*</em></label>
                                        <div class="col-10 col-md-6 input-wrapper">
                                          <v-select v-if="update_allowed['details'] && (role !== 'viewer')" :id="'category' + index" :ref="'program_category' + index" placeholder="Select Category" v-model="program.category" label="text" :reduce="text => text.id" :options="categoryOptions"  @input="updateProgramPrice(program.category, index); updateForm('details','program_info.'+index+'.category',false)" :clearable="false"></v-select>
                                            <span class="readonly pl-2" v-text="readOnlyCategoryName(program.category)" v-if="!update_allowed['details'] || (role == 'viewer')"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group-beside row" v-if="program.price" >
                                    <label  class="col-2 col-md-6">Category Price:</label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input type="text" :value="formatPrice(program.price)"  class="form-control market_site_booklet" disabled>
                                    </div>
                                </div>
                                    <div class="form-group-beside row" :class="errorObject['details']['program_info'] || errorObject['details']['program_info.'+index+'.program_name'] ? 'required' : ''">
                                        <label :for="'program_title' + index" class="col-2 col-md-6">Program Name: <em class="yellow no-style">*</em></label>
                                        <div class="col-10 col-md-6 input-wrapper">
                                            <input name="program_title_safarifix_search" @change="updateForm('details','program_info.'+index+'.program_name',false)" type="text" v-model="program.program_name" :id="'program_title' + index"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control" @blur="titleCaseBlur(index, $event)" @keyup="titleCase(index, $event)"  :disabled="(role == 'viewer')?true:false">
                                        </div>
                                    </div>
                                <div class="form-group-beside row program_logo_preview_wrapper" :class="errorObject['details']['program_info'] || errorObject['details']['program_info.'+index+'.program_logo'] ? 'required' : ''">
                                    <label for="program_logo_preview" class="col-2 col-md-6">
                                        <div>Program Logo: <em class="yellow no-style">*</em></div>
                                        <div class="description">Must be square, 600x600px minimum, .jpg or .png<br>Image (logo or key art) must include program title and cannot have a transparent background.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <fyc-cropper
                                            v-if="update_allowed['details']"
                                            :indexval="index"
                                            :initval="program.program_logo"
                                            min-crop-width="600"
                                            min-crop-height="600"
                                            @deleteImage="deleteProgramImage(index)"
                                            @croppedImage="saveForValidationProgramInfo"
                                            :file-size-restriction="true"
                                            :machine_name="'program_logo'"
                                            :updating-form="updatingForm"
                                            :role="role"
                                        >
                                        </fyc-cropper>
                                        <div class="small-img image-available" v-if="!update_allowed['details']">
                                            <img :src="program.program_logo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="mt-2 btn btn-primary" @click.prevent="addAnotherProgram()" v-if="update_allowed['details']"><plus-circle-icon class="svg-24"></plus-circle-icon> Add another program</a>
                            <a href="#" v-show="enoughProgramsExist()"  v-if="update_allowed['details']" class="mt-2 ml-2 btn btn-primary createNewEpisode btn-padding-smaller" @click.prevent="removeProgram()"><minus-circle class="svg-accent"></minus-circle> Remove last program</a>

                            <section class="review-notes mt-5">
                                <h2>Acknowledgement and FYC Submission</h2>
                                <div class="acknowledgement-description wysiwyg-content" v-html="order_acknowledgement"></div>
                                <div class="signature-block" @click.prevent="signatureNeededPopover()">
                                    <div class="btn btn-primary btn-big" v-show="!orderinfo.signature" v-if="update_allowed['details']">
                                        <checkbox_empty></checkbox_empty> Click to sign
                                    </div>
                                    <img :src="orderinfo.signature" />
                                </div>
                                <div class="red warning" v-show="!orderinfo.signature">Order can only be submitted after all of the required information has been completed and the acknowledgement has been signed.</div>                </section>
                        </div>

                        <div class="form-actions form-actions-edit mt-5 pt-5">
                            <div class="d-flex justify-content-end">
                                <div class="text-right">
                                    <h2 class="text-right">Total price: {{formatPrice(orderinfo.cost)}}</h2>
                                    <button @click.prevent="SaveApplicationStep(1)" v-if="update_allowed['details'] && role != 'admin'" v-bind:disabled="!orderinfo.signature || updatingForm" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                                        <div>
                                            <span class="submit-text">Approve Order Details</span>
                                            <span>& Submit For Review</span>
                                        </div>
                                        <right-small></right-small>
                                    </button>

                                    <button v-if="role == 'admin'" @click.prevent="updateForm('', '',true)" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                                        <save-icon></save-icon>
                                        <div>
                                            <span class="submit-text">Save Order</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            <div class="accordion-item accordion-contact-details-wrapper mb-3" id="order-step-1">
                <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between">
                    <a @click.prevent="applicationStepSelector(1)" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2" :class="dynamicClass(1)">
                        <div class="position-absolute app-status-icons">
                            <Attention svg-class="svg-24 red-svg" v-if="iconArray.assets && iconArray.assets == 'incomplete'"></Attention>
                            <checkmark svg-class="svg-24 svg-green" v-if="iconArray.assets && iconArray.assets == 'complete'"></checkmark>
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                            <h3 class="mb-0">Viewing Platform Assets</h3>
                            <div class="accordion-status-wrapper d-flex align-items-center w-25" :class="statusForIndicatorsAssets.includes(orderinfo.asset_status) ? 'justify-content-between' : 'justify-content-end'">
                                <div class="d-flex align-items-center cursor-initial" :class="'pr-2 status-color-' + orderinfo.asset_status" v-if="statusForIndicatorsAssets.includes(orderinfo.asset_status)">
                                    <submitted svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'submitted'"></submitted>
                                    <question svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'need_more_info'"></question>
                                    <circle_check svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'approved' || orderinfo.asset_status == 'building' || orderinfo.asset_status == 'published'"></circle_check>
                                    <span class="text-uppercase">{{ orderinfo.asset_status.replace(/_/g, ' ') }}</span>
                                </div>
                                <up-chevron-icon class="svg-accent" :class="(orderinfo.application_step == 1) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                            </div>
                        </div>
                    </a>
                </div>

                <div id="accordion-order-assets" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="orderinfo.application_step == 1 ? 'show' : ''">
                    <div class="full-instructions-wrapper fields-wrapper-row pl-30">
                        <h3 class="order-header">Contact For Viewing Platform Assets</h3>
                        <div class="form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['email_asset_contact_name'] ? 'required' : ''">
                            <label for="email_asset_contact_name" class="col-2 col-md-6">Email Asset Contact Name: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('assets','email_asset_contact_name',false)" type="text" v-model="orderinfo.email_asset_contact_name" name="email_asset_contact_name" id="email_asset_contact_name" class="form-control email_asset_contact_name" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['email_asset_contact_email'] ? 'required' : ''">
                            <label for="email_asset_contact_email" class="col-2 col-md-6">
                                Email Asset Contact Email: <em class="yellow no-style">*</em>
                                <div class="description pl-4 mt-1" v-if="errorObject['assets'] && errorObject['assets']['email_asset_contact_email']"><p class="red-font">{{ errorObject['assets']['email_asset_contact_email'][0] }}</p></div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('assets','email_asset_contact_email', false)" type="email" v-model="orderinfo.email_asset_contact_email" id="email_asset_contact_email" class="form-control email_asset_contact_email" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['email_asset_contact_phone'] ? 'required' : ''">
                            <label for="email_asset_contact_phone" class="col-2 col-md-6">Email Asset Contact Phone: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('assets','email_asset_contact_phone', false)" type="text" v-model="orderinfo.email_asset_contact_phone" id="email_asset_contact_phone" class="form-control email_asset_contact_phone" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="pt-2 mb-5 d-flex justify-content-end align-items-center" v-if="role == 'admin'">
                            <a @click.prevent="updateForm('','',true)" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                                <save-icon></save-icon>
                                <div>
                                    <span class="submit-text">Save Order</span>
                                </div>
                            </a>
                        </div>


                        <h3 class="order-header mt-5 mb-3">FYC Viewing Platform Emails</h3>
                        <div class="description" v-html="order_vp_email_description">
                        </div>

                        <template v-for="month in ['March', 'April','May', 'June']">
                            <order-vp-email
                                @submitEmail="emailSyncWithOrder"
                                :excluded_dates="disabled_dates"
                                :month="month"
                                :active="email_component_accessible[month]"
                                :orderinfo="orderinfo"
                                :email_statuses="asset_statuses"
                                :update_allowed="update_allowed"
                                :role="role"
                                :viewing_platform_expiry_date="viewing_platform_expiry_date"
                                order_type="network" />
                        </template>

                    </div>
                </div>

            </div>

            <div v-if="emailsStatuses && Object.keys(emailsStatuses).every(key => emailsStatuses[key])" class="form-actions form-actions-edit border-top-2-dark mt-5 pt-5">
                <div class="d-flex justify-content-end">
                    <a href="/checkout" class="btn btn-primary btn-step-next">
                        <span class="submit-text">Checkout</span>
                    </a>
                </div>
            </div>

            <div class="form-actions form-actions-edit border-top-2-dark mt-5 pt-5" v-else-if="role == 'submitter'">
                <div v-if="update_allowed['assets']" class="d-flex justify-content-between">
                    <button class="btn btn-delete" @click.prevent="deleteModal = true"  v-if="orderinfo.order_status != 'approved' && orderinfo.order_status != 'canceled'">Delete</button>
                    <a :href="ordersurl" class="save-button btn btn-primary" id="SaveButton"><save-icon></save-icon> Save for Later</a>
                </div>
            </div>
        </div>

        <modal v-if="deleteModal" @close="deleteModal = false">
            <h3 slot="header">Delete Submission</h3>
            <div class="content" slot="body">
                <p>Are you sure?</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn btn-transparent" @click="deleteModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteOrder">Yes, Delete</button>
            </div>
        </modal>

        <modal v-if="deleteProgramModal" @close="deleteProgramModal = false">
            <h3 slot="header">Delete Program</h3>
            <div class="content" slot="body">
                <p>You are deleting {{ programDelete.program_name }}. Are you sure?</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn btn-transparent" @click="deleteProgramModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteProgram(programDeleteIndex)">Yes, Delete</button>
            </div>
        </modal>

        <modal v-if="signatureNeeded" @close="signatureNeeded = false" modalclass="modal-signature">
            <h2 class="description" slot="header">Sign in the window below using your track pad or mouse</h2>
            <div class="content" slot="body">
                <signature-pad @closeModalClicked="closeModal" @signatureSave="saveSignature"></signature-pad>
            </div>
            <div class="form-group-submit" slot="footer">
            </div>
        </modal>

        <modal class="limited-width-modal" v-if="showOrderDetailsCompletePopup" @close="showOrderDetailsCompletePopup = false">
            <h3 slot="header">Submit Viewing Platform Order For Approval</h3>
            <div class="content" slot="body">
                <p>
                    Please click below to submit. Upon submission, the Television Academy FYC team will review your order and email details.
                    Meanwhile, please continue by providing the required assets in the next section.
                </p>
            </div>
            <div class="form-group-submit" slot="footer">
                <a class="btn btn-black pull-left" @click="showOrderDetailsCompletePopup = false">Close</a>
                <button class="btn btn-primary pull-right" @click.prevent="submitForm()">Submit Now</button>
            </div>
        </modal>

    </div>
</template>

<script>
    // Import component
    import modal from '../components/Modal.vue';
    import FycCropper from '../components/FycCropper.vue';
    import EditIcon from "@/Icons/edit.vue";
    import TrashIcon from "@/Icons/trash.vue";
    import PlusCircleIcon from "@/Icons/plus-circle.vue";
    import MinusCircle from "@/Icons/minus-circle.vue";
    import SaveIcon from "@/Icons/save.vue";
    import RightSmall from "@/Icons/right-small.vue";
    import CalendarIcon from "@/Icons/calendar.vue";
    import Checkmark from "@/Icons/checkmark.vue";
    import AdminOrderNextPrev from "@/components/AdminOrderNextPrev.vue";
    import Attention from "@/Icons/attention.vue";
    import Submitted from "@/Icons/submitted.vue";
    import Question from "@/Icons/question.vue";
    import Circle_check from "@/Icons/circle_check.vue";
    import UpChevronIcon from "@/Icons/up-chevron.vue";
    import OrderVpEmail from "@/components/ViewingPlatform/OrderVpEmail.vue";
    import Checkbox_empty from "@/Icons/checkbox_empty.vue";
    import CircleFilledIcon from "@/Icons/circle_filled.vue";
    import circle_icon from "@/Icons/circle_icon.vue";
    import CloseIcon from "@/Icons/close.vue";
    import Download from "@/Icons/download.vue";

    export default {
        components: {
            Download,
            CloseIcon,
            CircleFilledIcon,
            circle_icon,
            Checkbox_empty,
            UpChevronIcon,
            Circle_check,
            Question,
            Submitted,
            Attention,
            Checkmark,
            CalendarIcon,
            RightSmall,
            SaveIcon,
            MinusCircle,
            PlusCircleIcon,
            TrashIcon,
            EditIcon,
            modal,
            FycCropper,
            AdminOrderNextPrev,
            OrderVpEmail
        },
        props: {
            actionurl_assets: String,
            actionurl_details: String,
            actionurl_team_assigned: String,
            actionurl: String,
            asset_statuses: Array,
            categories: Array,
            email_component_accessible: Array,
            emmy_or_network: String,
            excluded_dates: Array,
            hold_until_max_date: String,
            hold_until_min_date: String,
            invoice_payment_edit: String,
            invoice_show: String,
            navigation: Object,
            order_acknowledgement: String,
            order: Array,
            ordersurl: String,
            role: String,
            submission_statuses: Array,
            team_assigned_members: Array,
            update_allowed: Array,
            user_change_url: String,
            user_suggestions_url: String,
            viewing_platform_expiry_date: String,
        },
        data() {
            return {
                allowedStatusForEditing: ['in_progress', 'need_more_info'],
                statusForIndicators: ['need_more_info', 'submitted', 'approved', 'approved_for_build'],
                statusForIndicatorsAssets: ['need_more_info', 'submitted', 'approved', 'building', 'published'],
                emailStatusOptionsBeforeCheckout: ['in_progress', 'need_more_info', 'canceled'],
                orderinfo: this.order,
                fullPage: true,
                signatureNeeded: '',
                deleteModal: false,
                deleteProgramModal: false,
                programDelete: [],
                programDeleteIndex: '',
                errorsJson: [],
                categoryOptions: [],
                errorCodes: [401, 403, 419],
                disabled_dates: this.excluded_dates,
                componentKey: 1,
                toggleClass: {
                    'access_codes_required': '',
                },
                emailsStatuses: {
                    'March': false,
                    'April': false,
                    'May': false,
                    'June': false,
                },
                cable_networkOptions: ["Other","A&E","ABC","ABC\/Syndicated","Acorn TV","Adult Swim","ALLBLK","Amazon Freevee","AMC","AMC+","Apple TV+","BBC America","BET+","BET Networks","Bravo","BritBox","BYUtv","CBS","CMT","CNBC","CNN","Comedy Central","Crackle","CW","Discovery+","Discovery Channel","Disney+","Disney XD","E!","ESPN","ESPN+","Facebook Watch","Food Network","FOX","Freeform","FunnyOrDie","Fuse","FX","GSN","Hallmark Channel","Hallmark Movies & Mysteries","HBO | Max","HGTV","HISTORY","Hulu","IFC","IGTV","Investigation Discovery","Lifetime","Logo","MGM+","MSNBC","MTV","Nat Geo WILD","National Geographic","NBC","Netflix","NFL Network","OWN","Oxygen","Paramount+","Paramount Network","PBS","Peacock","Pluto","Prime Video","Reelz","Revry","Roku Channel","Science Channel","Showtime","Smithsonian Channel","Snapchat","Spectrum","STARZ","Sundance Now","SundanceTV","SYFY","TBS","Telemundo","TLC","TNT","Travel Channel","truTV","Tubi","UNIVISION","USA","VH1","VICE TV","WWE Network","YouTube","YouTube Originals"],
                platformOptions: [
                    'Website',
                    'iOS',
                    'iPadOS',
                    'Apple TV',
                    'Android',
                    'Android TV',
                    'Roku',
                    'Amazon Fire/Fire TV',
                    'Chromecast',
                    'Samsung OTT',
                    'Vizio OTT',
                    'LG OTT'
                ],
                marketing_mailOptions: [
                    'North America',
                    'North America + UK',
                    'Global'
                ],
                marketing_membership_groups: [
                    'Voting members only',
                    'Voting members and non-voting members',
                ],
                config: {
                    dateFormat: 'm-d-Y',
                    minDate: "1.1.2024",
                    maxDate: "9.30.2024",
                    static: true,
                },
                marketingMethodOptions: [
                    'Mailed booklet',
                    'Mailed postcard',
                    'Branded email (via Television Academy)'
                ],
                deleteProgramAllowed: false,
                hasSpecialCharacter: false,
                iconArray: {
                    'details': {},
                    'assets': {},
                    'post_event': {}
                },
                errorObject: {
                    'details': {},
                    'assets': {},
                    'post_event': {}
                },
                savingData: false,
                clickedContinue: false,
                inputStepClicked: '',
                updatingForm: false,
                showOrderDetailsCompletePopup: false,
                submission_statuses_submitter: [{
                    text: "In Progress",
                    id: "in_progress"
                }, {
                    text: "Submitted",
                    id: "submitted"
                }, {
                    text: "Canceled",
                    id: "canceled"
                }, {
                    text: "Approved",
                    id: "approved_for_build"
                }, {
                    text: "Approved",
                    id: "approved"
                }, {
                    text: "Ineligible",
                    id: "ineligible"
                }, {
                    text: "Live",
                    id: "live"
                }, {
                    text: "Live",
                    id: "live_checked"
                },{
                    text: "Need More Information",
                    id: "need_more_info"
                },
                {
                    text: "User Reported",
                    id: "user_reported"
                }],
            }
        },
        created: function() {
            this.orderinfo.program_info = this.orderinfo.program_info_preview;

        },
        mounted: function() {
            // Collapse accordion by defaults when in admin view
            if (this.role === 'admin') {
                this.orderinfo.application_step = -1;
            }

            this.orderinfo.program_info = this.orderinfo.program_info_preview;
            this.createCategorySelectList();

            // Initialize JSON object here
            if((this.orderinfo.program_info == null) || (this.orderinfo.program_info.length == 0)) {
                this.orderinfo.program_info = [{}];
            }
            this.costCalculator();
            if(! this.update_allowed['details']) {
                this.inputReadOnly('#accordion-order-details');
            }

            this.emailStatusesCreator();

            if(! this.update_allowed['assets']) {
                this.inputReadOnly('#accordion-order-assets');
            }
        },
        methods: {
            siteLiveDateConfigCallback(selectedDates, dateStr, instance, $event) {
                this.updateForm('details','site_live_date',false)
            },
            siteCloseDateConfigCallback(selectedDates, dateStr, instance) {
                this.updateForm('details','site_close_date',false)
            },
            cropImage() {
                // get image data for post processing, e.g. upload or setting image src
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
                this.orderinfo.cable_network_logo = this.$refs.cropper.getCroppedCanvas().toDataURL();
            },
            async onSubmit() {
                let that = this;

                // Loop through program_info field to find missing images and return isValid value
                if(that.orderinfo.program_info) {
                    that.orderinfo.program_info.forEach( function(item, index) {
                        if(item.program_logo == undefined) {
                            that.errorsJson[index] = 'required';
                        }
                    });
                }

                // Confirm the required Json fields have been filled out.
                if((that.orderinfo.program_info[0].category == null) || (that.orderinfo.program_info[0].program_name == null) || (that.orderinfo.program_info[0].program_logo == null)) {
                    if(that.orderinfo.program_info && that.orderinfo.program_info.length == 0) {
                        this.orderinfo.program_info = [{}];
                    }
                } else {
                    // Now Trim any JSON objects necessary
                    that.orderinfo.program_info = this.trimArray(that.orderinfo.program_info);
                }

                this.submitForm();
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                if((val == 0.00) || (val == 'NaN')) {
                    return '$0';
                }
                return '$'+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            trimArray: function(arrayToTrim) {
                // Trimming the empty values
                let that = this;
                let cleanedProgramInfo = [];
                arrayToTrim.forEach(function(i,v) {
                    if((arrayToTrim[v].category) || (arrayToTrim[v].program_name)) {
                        cleanedProgramInfo.push(arrayToTrim[v]);
                    }
                });
                return cleanedProgramInfo;
            },
            toggleFunctionality: function(fieldName, value, saveData, form_section) {
                this.orderinfo[fieldName] = value;
                if(this.orderinfo[fieldName] == 1) {
                    this.toggleClass[fieldName] = 'active-yes' + (this.update_allowed[form_section] ? '' : ' no-hover');
                } else if (this.orderinfo[fieldName] == 0) {
                    this.toggleClass[fieldName] = 'active-no' + (this.update_allowed[form_section] ? '' : ' no-hover');
                }

                if(saveData != 'onload') {
                    this.updateForm(form_section, fieldName, false);
                }
            },
            updateForm: function(field_group, field_name, adminSave) {
                let that = this;
                if((that.role == 'admin') && (adminSave != true)) {
                    return false;
                }
                if(that.updatingForm) {
                    return;
                }
                that.updatingForm = true;

                // Remove required field 'required' indicator if field is filled in
                if(field_group && field_name) {
                    that.errorObject[field_group][field_name] = '';
                }
                // Clear required for first instance of program_info
                if(field_name && field_name.includes('program_info')) {
                    that.errorObject['details']['program_info'] = '';
                }

                window.axios(
                    {
                        method: 'put',
                        url: this.actionurl,
                        data: that.orderinfo
                    }).then(function(response) {
                        if((that.role == 'admin') || (adminSave)) {
                            location.reload();
                        }
                        let dataBack = response.data;
                        if (dataBack.program_info.length === that.orderinfo.program_info.length) {
                            that.orderinfo.program_info.forEach(function(item, index){
                                if (dataBack.program_info[index].program_logo !== ""
                                  && item.program_logo !== dataBack.program_info[index].program_logo) {
                                    that.orderinfo.program_info[index].program_logo = dataBack.program_info[index].program_logo;
                                }
                            });
                        }
                        that.disabled_dates = dataBack.excluded_dates;
                        that.updatingForm = false;

                        // If the network is changed, reload the page. We do this so the email subject line can be updated with the new network name.
                        if(dataBack.emails_started > 0 && (field_name == 'cable_network' || field_name == 'cable_network_other')) {
                            location.reload();
                        }
                }).catch(function (err) {
                        if(err.response && that.errorCodes.includes(err.response.status)) {
                            window.location.reload();
                        }
                        if (err.response) {
                            let errorMessage = err.response.data.message;
                            that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                            that.$emit('showflashalert');
                            that.$scrollTo('#top-of-form', 700);
                        }
                        that.updatingForm = false;
                    });
            },
            submitForm: function() {
                let that = this;
                if(that.updatingForm) {
                    return;
                }
                that.updatingForm = true;

                that.orderinfo.form_status = 'submitted';
                window.axios({
                        method: 'put',
                        url: this.actionurl,
                        data: that.orderinfo
                    }).then(function(response) {
                        let dataBack = response.data;
                        if(dataBack.redirect_destination) {
                            window.location.href = dataBack.redirect_destination;
                        }
                        that.updatingForm = false;
                    }).catch(function (err) {
                        if(err.response && that.errorCodes.includes(err.response.status)) {
                            window.location.reload();
                        }
                        that.updatingForm = false;
                    });
            },
            deleteOrder: function() {
                let that = this;
                if(that.updatingForm) {
                    return;
                }
                that.updatingForm = true;

                window.axios(
                    {
                        method: 'delete',
                        url: this.actionurl,
                        data: that.orderinfo
                    }).then(function(response) {
                    let dataBack = response.data;
                    window.location.href = dataBack.redirect_to;
                    that.updatingForm = false;
                }).catch(function (err) {
                    if(err.response && that.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    that.updatingForm = false;
                });
            },
            signatureNeededPopover: function() {
                let that = this;
                if(!that.update_allowed['details']) {
                    return false;
                }
                document.body.classList.add("overlay");
                that.signatureNeeded = true;
            },
            closeModal: function() {
                document.body.classList.remove("overlay");
                this.signatureNeeded = false;
                this.network_logo_needed = false;
            },
            saveSignature: function(signature) {
                this.orderinfo.signature = signature;
                this.signatureNeeded = false;
                this.updateForm('details','signature',false);
            },
            saveForValidation: function(filename, field_name, fileData) {
                this.orderinfo[field_name] = fileData;

                let field_group = '';
                if((field_name == 'cable_network_logo_preview') || (field_name == 'program_logo_preview')) {
                    field_group = 'details';
                } else {
                    field_group = 'assets';
                }
                this.updateForm(field_group, field_name,false);
            },
            saveForValidationProgramInfo: function(filename, field_name, fileData, index) {
                this.orderinfo.program_info[index][field_name] = fileData;
                this.updateForm('details','program_info.'+index+'.program_logo',false);
            },
            fileSaveForValidation: function(filename, field_name, fileData) {
                this.orderinfo[field_name] = filename;
                this.errorObject['details']['field_name'] = '';
            },
            createCategorySelectList: function() {
                let that = this;
                this.categories.forEach(function (item, index) {
                    that.categoryOptions.push({
                        id: item.id,
                        text: item.name
                    })
                });
            },
            deleteProgram: function(index) {
                this.orderinfo.program_info.splice(index, 1);
                this.updateForm('','',true);
            },
            deleteProgramModalActivate: function(item, index) {
                this.programDelete = item;
                this.programDeleteIndex = index;
                this.deleteProgramModal = true;
            },
            addAnotherProgram: function() {
                let that = this;
                that.orderinfo.program_info.push({});
                that.orderinfo.program_info[that.orderinfo.program_info.length-1].key = that.orderinfo.program_info[that.orderinfo.program_info.length-2].key + 1;
            },
            removeProgram: function() {
                var lastIndex = this.orderinfo.program_info.length - 1;
                this.orderinfo.program_info.splice(lastIndex, 1);
                this.costCalculator();
                this.updateForm('','',false);
            },
            enoughProgramsExist: function() {
                if ((this.orderinfo.program_info != null) && (this.orderinfo.program_info.length > 1)) {
                    return true;
                } else {
                    return false;
                }
            },
            updateProgramPrice: function(categoryId, index) {
                var programPrice = this.categories.filter(obj => {
                    return obj.id == categoryId
                })
                this.orderinfo.program_info[index].price = programPrice[0].price;
                this.costCalculator();
            },
            readOnlyCategoryName: function(categoryId, index) {
                var programInfo = this.categories.filter(obj => {
                    return obj.id == categoryId
                })
                if(programInfo[0]) {
                    return programInfo[0].name
                }
                return '';
            },
            costCalculator: function() {
                this.orderinfo.cost = 0;
                let program = this.orderinfo.program_info;
                program.forEach((item) => {
                    if (typeof item.price !== 'undefined') {
                        this.orderinfo.cost += item.price;
                    }
                });
            },
            inputReadOnly: function(accordion_wrapper_id) {
                let wrapper = accordion_wrapper_id + ' input, ' + accordion_wrapper_id + ' textarea';
                let textareas=document.querySelectorAll(wrapper),
                    x=textareas.length;
                while(x--)
                    textareas[x].readOnly=1;
            },
            multiselectFormatted: function(value) {
                var readonlyFormatted = JSON.stringify(value);
                readonlyFormatted = readonlyFormatted.replace(/","/g, '"; "');
                readonlyFormatted = readonlyFormatted.replace(/]|\[|"/g, '');
                return readonlyFormatted
            },
            titleCaseBlur: function(indexval, evt) {
                if(this.role !== 'admin') {
                    var string_split = evt.target.value.toLowerCase().split(" ");
                    for (var i = 0; i < string_split.length; i++) {
                        var j = string_split[i].charAt(0).toUpperCase();
                        string_split[i] = j + string_split[i].substr(1);
                    }
                    evt.target.value = string_split.join(" ");
                    this.orderinfo.program_info[indexval].program_name = string_split.join(" ");
                }

            },
            titleCase: function(indexval, evt) {
                if(this.role !== 'admin') {
                    var start = evt.target.selectionStart;
                    var end = evt.target.selectionEnd;
                    var string_split = evt.target.value.toLowerCase().split(" ");
                    for (var i = 0; i < string_split.length; i++) {
                        var j = string_split[i].charAt(0).toUpperCase();
                        string_split[i] = j + string_split[i].substr(1);
                    }
                    evt.target.value = string_split.join(" ");
                    evt.target.setSelectionRange(start, end);
                }
            },
            deleteImage: function(fieldName) {
                this.orderinfo[fieldName] = '';
                this.updateForm('','',false);
            },
            deleteProgramImage: function(index) {
                this.orderinfo.program_info[index].program_logo = '';
                this.updateForm('','',false);
            },
            noSpecialCharactersCheck: function() {
                this.hasSpecialCharacter = false;
                if(!(/^[a-zA-Z0-9]+$/.test(this.orderinfo.network_access_code_test))) {
                    if(this.orderinfo.network_access_code_test == 'n/a' || this.orderinfo.network_access_code_test == 'N/A') {
                        this.hasSpecialCharacter = false;
                    } else {
                        this.hasSpecialCharacter = true;
                    }
                } else {
                    this.hasSpecialCharacter = false;
                }
                this.updateForm('','',false);
            },
            dynamicClass(input_step) {
                if(this.orderinfo.application_step == -1) {
                    return;
                }
                return (this.orderinfo.application_step < input_step) ? 'greyed-out' : '';
            },
            applicationStepSelector(input) {
                if(input == this.orderinfo.application_step) {
                    this.orderinfo.application_step = -1;
                } else {
                    this.orderinfo.application_step = input;
                    this.$scrollTo('#order-step-'+(input-1), 700);
                }
            },
            SaveApplicationStep(inputStep) {
                this.inputStepClicked = inputStep
                this.clickedContinue = true;
                if(!this.savingData) {
                    this.actualSaveApplicationStep(this.inputStepClicked)
                }
            },
            actualSaveApplicationStep() {
                let app = this;
                if(app.inputStepClicked === app.orderinfo.application_step) {
                    app.orderinfo.application_step = -1;
                    return;
                }
                if(app.updatingForm) {
                    return;
                }
                app.updatingForm = true;

                axios.post('/order/step/update/network', {
                    'order_id': app.orderinfo.order_id,
                    'application_step': app.inputStepClicked-1
                }).then(function(response) {
                    // app.orderinfo = response.data.orderData;
                    app.iconArray = response.data.status_icons;
                    app.clickedContinue = false
                    if(response.data.errors) {
                        app.errorObject = response.data.errors;
                    }

                    app.orderinfo.program_info = app.orderinfo.program_info_preview;
                    if((app.orderinfo.program_info == null) || (app.orderinfo.program_info.length == 0)) {
                        app.orderinfo.program_info = [{}];
                    }

                    // Asset Details and/or Order Details complete, no errors get them to submit.
                    if((app.inputStepClicked == 2) && (_.size(app.errorObject.details) == 0) && (_.size(app.errorObject.assets) == 0)) {
                        app.showOrderAssetsCompletePopup = true;
                    } else if((app.inputStepClicked == 1) && (_.size(app.errorObject.details) == 0)) {
                        app.showOrderDetailsCompletePopup = true;
                    } else {
                        app.$scrollTo('#top-of-form', 700);
                        app.$root.$refs.flash_alert.activateFlashAlert('Fill in all the required fields before proceeding.', 'error');
                        app.showErrorMessage = true;
                    }
                    app.updatingForm = false;

                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }

                    let errorMessage = err.response.data.message;
                    app.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    app.showErrorMessage = true;
                    app.updatingForm = false;
                });
            },
            emailSyncWithOrder: function(month) {
                this.emailsStatuses[month] = true;

                let emailIndex = this.orderinfo.emails.findIndex(email => email.month == month);
                if(emailIndex > -1) {
                    this.orderinfo.emails[emailIndex].email_status = 'submitted';
                }
            },
            emailStatusesCreator: function() {
                let that = this;
                this.orderinfo.emails.forEach((email) => {
                    that.emailsStatuses[email.month] = !that.emailStatusOptionsBeforeCheckout.includes(email.email_status);
                });
            }
        }
    }
</script>

<style>

</style>
