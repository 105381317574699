<template>
    <div>
        <div @click="collapsed = !collapsed" class="cursor-pointer profile-accordion border-bottom-1-grey6 d-flex align-items-center justify-content-between mb-3 toggle-with-up-icon text-decoration-none">
            <h3 class="primary mb-0">{{ title }}</h3>
            <down-chevron-icon class="float-end svg-24 svg-accent" v-if="collapsed"></down-chevron-icon>
            <up-chevron-icon class="float-end svg-24 svg-accent" v-else></up-chevron-icon>
        </div>

        <div class="collapse show profile-info-wrapper" id="collapseAboutEvent" v-show="!collapsed" v-if="orderinfo.type != 'Virtual'">
            <div class="event-accordion-content-wrapper mb-4">
                <div class="section-sub-label p3 light-50 lh-1 mb-1">Venue Location</div>
                <div class="section-sub" v-if="orderinfo.wolf_theatre == 0 && (orderinfo.venue_name || (orderinfo.venue_address && orderinfo.venue_address.street_1 != null))">
                    <div><p class="mb-0">{{ orderinfo.venue_name }}</p></div>
                    <div v-if="orderinfo.venue_address && orderinfo.venue_address.street_1 != null">
                        <p>{{ orderinfo.venue_address.street_1 }} <br/>
                            <template v-if="orderinfo.venue_address.street_2 != null && orderinfo.venue_address.street_2 != ''">{{ orderinfo.venue_address.street_2 }} <br/> </template>
                            {{ orderinfo.venue_address.city }}<template v-if="orderinfo.venue_address.state != null">, {{ orderinfo.venue_address.state }} {{ orderinfo.venue_address.postal_code }}</template>
                        </p>
                    </div>
                </div>
                <div class="section-sub" v-else-if="orderinfo.wolf_theatre == 1">
                    <div><p class="mb-0 font-weight-bold f-16">Saban Media Center</p></div>
                    <div>
                        <p>5210 Lankershim Blvd, North Hollywood,<br/>
                            CA 91601, United States
                        </p>
                    </div>
                </div>

                <div class="section-sub" v-if="orderinfo.parking_details && orderinfo.parking_details_html">
                    <div class="section-sub-label p3 light-50 lh-1 mb-1">Venue Parking Location</div>
                    <div class=""><p v-html="orderinfo.parking_details_html"></p></div>
                </div>
                <div>
                    <a :href="'https://www.google.com/maps/dir/?api=1&destination=' + destination_encoded + '&travelmode=driving'" class="d-block" target="_blank"  v-if="(orderinfo.wolf_theatre == 0 && (orderinfo.venue_name || (orderinfo.venue_address && orderinfo.venue_address.street_1 != null)))">Get Directions</a>
                    <a :href="'https://maps.app.goo.gl/o9jW6biv31Dm7AWt5'" class="d-block" target="_blank" v-else>Get Directions</a>
                </div>
            </div>
            <p class="f-14 f-600" v-if="orderinfo.type == 'Hybrid' && orderinfo.platform_used == 'TVA Viewing Platform'">
                This event will also be presented on the Television Academy's <a href="https://www.emmys.com/viewing/fyc-events" target="_blank" class="no-hover no-click" @click.prevent="">Viewing Platform</a>.
            </p>
        </div>
        <div class="collapse show profile-info-wrapper" id="collapseAboutEvent" v-show="!collapsed" v-else>
            <div class="section-sub-label p3 light-50 lh-1 mb-1">Venue Location</div>
            <p class="mb-0 f-14" v-if="orderinfo.platform_used == 'TVA Viewing Platform'">
                This is a streaming event, and will be presented on the Television Academy's <a href="https://www.emmys.com/viewing/fyc-events" target="_blank" class="no-hover no-click" @click.prevent="">Viewing Platform</a>.
            </p>
            <p class="mb-0 f-14" v-else-if="orderinfo.platform_used == 'Own Platform'">
                This event will be presented online. Event link and details will be sent to registered members prior to the event.
            </p>
        </div>
    </div>
</template>

<script>
import UpChevronIcon from "@/Icons/up-chevron.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";

export default {
    components: {
        DownChevronIcon,
        UpChevronIcon,
    },
    props: {
        title: String,
        orderinfo: Array
    },
    data() {
        return {
            collapsed: false,
            destination_encoded: '',
        }
    },
    mounted() {
        if(this.orderinfo.venue_address != null) {
            this.destination_encoded = encodeURIComponent(this.orderinfo.venue_name + ' ' + this.orderinfo.venue_address.street_1 + ' ' + this.orderinfo.venue_address.city + ' ' + this.orderinfo.venue_address.state + ' ' + this.orderinfo.venue_address.postal_code);
        }
    }
}

</script>
