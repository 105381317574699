<template>

    <div class="col-12">
        <div v-if="errors">
            {{ errors }}
        </div>
        <div class="accordion-item accordion-date-wrapper" >
            <div v-for="(groupDate, b, dateEvent) in events">
                <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between" >
                    <h2 class="mt-4 mb-2">{{ groupDate[0].event_date }}</h2>
                </div>
                <div id="accordian-details" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse">
                    <div v-for="event in groupDate">
                    {{ event.time_slot_value}} {{ event.network_platform == 'Other' ? event.network_platform_other : event.network_platform }}  - {{ event.program_title}}
                    <a :href="'/event/' + event.order_id" v-if="role == 'admin'" >({{ event.order_id}})</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CloseIcon from "@/Icons/close.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";
import UpChevronIcon from "@/Icons/up-chevron.vue";
    export default {
        name: "EventsCalendar",
        components: {UpChevronIcon, DownChevronIcon, CloseIcon},
        props: {
            events: Array,
            role: String,
        },
        data() {
            return {
                errors: false,
            }
        },

    }
</script>
