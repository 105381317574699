<template>
    <div>
        <div class="actionable-wrapper" :class="hide_actionable_area ? 'd-none' : ''">
            <a v-show="!cropImg" href="#" @click.prevent="uploadFileClick()" class="btn btn-primary" :class="updatingForm ? 'disabled' : ''"><upload></upload> Upload Image</a>
            <input
                :ref="'input' + indexval"
                type="file"
                :accept="allowed_filetypes"
                @change="setImage"
                class="hide-me"
            />

            <a href="#" @click.prevent="deleteFileClick()" v-show="cropImg" class="btn btn-delete pull-right"><trash-icon></trash-icon> Delete</a>
            <div class="spinner large" v-if="cropClicked && updatingForm"></div>
            <div class="small-img" v-else-if="cropImg" :class="cropImg ? 'image-available' : ''" @click="crop_or_uploadNewFile()">
                <img :src="cropImg">
            </div>
        </div>

        <div v-show="showCropImagePopup" @close="showCropImagePopup = false">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header justify-content-center mb-0 pb-0">
                            <div class="text-center">
                                <h2 slot="header" class="mb-2">Select image crop</h2>
                                <div class="instructions-text">
                                    <p v-if="hasAspectRatio" class="mb-0">Image will be resized to {{ minCropWidth }}px by {{ minCropHeight }}px.</p>
                                    <p v-else class="mb-0">Image will be resized to {{ minCropWidth }}px. Height will be between {{ minCropHeight }}px and {{ maxCropHeight }}px.</p>
                                    <p class="red mb-0" v-if="invalidCrop">Crop area too small. Crop dimensions are: {{ cropDimensionWidth }}px by {{ cropDimensionHeight }}px.</p>
                                    <p class="red mb-0" v-else-if="invalidCropAspectRatio">Crop aspect ratio wrong. {{ invalidCropAspectRatioMessage }}</p>
                                    <p class="mb-0" v-else>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body">
                            <vue-cropper ref="cropper"
                                         :viewMode="2"
                                         :src="imgSrc"
                                         :aspect-ratio="minCropWidth / minCropHeight"
                                         preview=".preview"
                                         :zoomable="false"
                                         :movable="false"
                                         :rotatable="false"
                                         :scalable="false"
                                         :background="false"
                                         :crop="myCropFunction"
                                         :style="'max-width: 400px; max-height: 400px; margin: 0 auto;'"
                                         autoCropArea="1"
                                         v-if="hasAspectRatio"
                            />
                            <vue-cropper ref="cropper"
                                         :viewMode="2"
                                         :src="imgSrc"
                                         preview=".preview"
                                         :zoomable="false"
                                         :movable="false"
                                         :rotatable="false"
                                         :scalable="false"
                                         :background="false"
                                         :crop="myCropFunction"
                                         :style="'max-width: 400px; max-height: 400px; margin: 0 auto;'"
                                         autoCropArea="1"
                                         v-else
                            />
                        </div>
                        <div class="modal-footer">
                            <div class="m-signature-pad--footer">
                                <button type="button" class="btn btn-primary pull-right button save" @click.prevent="cropImage()" :disabled="(invalidCrop || invalidCropAspectRatio) ? true:false">Crop and Save Image</button>
                                <button type="button" class="btn btn-cancel"  @click.prevent="closeModal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal v-if="deleteImageModal" @close="deleteImageModal = false">
            <h3 slot="header">Remove Image</h3>
            <div class="content" slot="body">
                <p>Are you sure you want to remove this image?</p>
            </div>
            <div class="form-group-submit d-flex justify-content-between align-items-center" slot="footer">
                <button class="btn btn-primary btn-transparent" @click.prevent="deleteImageModal = false">Close</button>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="spinner xsmall mr-2" v-if="deleteFileClicked"></div>
                    <button class="btn btn-primary" @click.prevent="deleteFile">Yes, Delete</button>
                </div>
            </div>
        </modal>

        <modal v-if="modalProblemUploading" @close="modalProblemUploading = false">
            <h3 slot="header">Problem Uploading File</h3>
            <div class="content" slot="body">
                <div>Incorrect image or crop size. Image should be at least {{ minCropWidth }} x {{ minCropHeight }} pixels. </div>
            </div>
            <div class="form-group-submit clearfix" slot="footer">
                <button class="pull-right btn btn-primary" @click.prevent="modalProblemUploading = false">Close</button>
            </div>
        </modal>

        <modal v-if="modalProblemFileSize" @close="modalProblemFileSize = false">
            <h3 slot="header">Problem Uploading File</h3>
            <div class="content" slot="body">
                <div>The image uploaded is over the 400KB file size limit. Please try another file. </div>
            </div>
            <div class="form-group-submit clearfix" slot="footer">
                <button class="pull-right btn btn-primary" @click.prevent="modalProblemFileSize = false">Close</button>
            </div>
        </modal>

        <modal v-if="modalFileTooBig" @close="modalFileTooBig = false">
            <h3 slot="header">Problem Uploading File</h3>
            <div class="content" slot="body">
                <div>The image uploaded is over the 10MB file size limit. Please crop the image smaller, reduce the file size, or try another file. </div>
            </div>
            <div class="form-group-submit clearfix" slot="footer">
                <button class="pull-right btn btn-primary" @click.prevent="modalFileTooBig = false">Close</button>
            </div>
        </modal>

    </div>
</template>

<script>
    import modal from '../components/Modal.vue';
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import TrashIcon from "@/Icons/trash.vue";
    import Upload from "@/Icons/upload.vue";

    export default {
        props: {
            initval: String,
            indexval: String,
            minCropWidth: String,
            minCropHeight: String,
            maxCropHeight: String,
            machine_name: String,
            hasAspectRatio: {
                type: String,
                default: true
            },
            aspectRatio: {
                type: String,
                default: true
            },
            hide_actionable_area: {
                type: String,
                default: false
            },
            updatingForm: {
                type: String,
                default: false,
            },
            role: {
                type: String
            },
            allowed_filetypes: {
                type: String,
                default: 'image/png, image/jpg, image/jpeg'
            },
            fileSizeRestriction: {
                type: Boolean,
                default: false
            }
        },
        components: {Upload, TrashIcon, modal, VueCropper},
        data() {
            return {
                cropClicked: false,
                imgSrc: '',
                cropImg: '',
                data: null,
                showCropImagePopup: false,
                lastValidCrop: [],
                filename: '',
                file_type: false,
                deleteImageModal: false,
                modalProblemUploading: false,
                modalFileTooBig: false,
                invalidCrop: false,
                invalidCropAspectRatio: false,
                invalidCropAspectRatioMessage: '',
                cropDimensionWidth: '',
                cropDimensionHeight: '',
                deleteFileClicked: false,
                modalProblemFileSize: false,
            }
        },
        mounted() {
            let that = this;
            if(this.initval) {
                this.cropImg = this.initval;
            }
            that.imgSrc = this.initval;
        },
        watch: {
            updatingForm: function() {
                // Watch the return of updatingForm from the parent component. If done updating remove the cropClicked attribute.
                if(this.updatingForm == false) {
                    this.cropClicked = false;
                    this.deleteImageModal = false;
                    this.deleteFileClicked = false;
                }
            }
        },
        methods: {
            uploadFileClick: function() {
                this.$refs['input' + this.indexval].value = '';
                this.$refs['input' + this.indexval].click()
                this.cropClicked = true;
            },
            deleteFileClick: function() {
                this.deleteImageModal = true;
            },
            deleteFile: function() {
              this.cropImg = '';
              this.$emit('deleteImage', this.cropImg, this.indexval);
              this.deleteFileClicked = true;
              if(this.role == 'admin') {
                  this.deleteImageModal = false;
              }
            },
            cropImage: function () {
                // get image data for post processing, e.g. upload or setting image src
                this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL(this.file_type);

                let croppedCanvas = this.$refs.cropper.getCroppedCanvas();

                if(!this.hasAspectRatio) {
                    let minAspectRatio = this.minCropHeight / this.minCropWidth;
                    let maxAspectRatio = this.maxCropHeight / this.minCropWidth;
                    let calculatedAspectRatio = croppedCanvas.height / croppedCanvas.width;
                    if(calculatedAspectRatio < minAspectRatio || calculatedAspectRatio > maxAspectRatio) {
                        this.modalProblemUploading = true;
                        return;
                    }
                }

                if(croppedCanvas.width < this.minCropWidth || croppedCanvas.height < this.minCropHeight) {
                    this.modalProblemUploading = true;
                    return;
                }

                var stringLength = this.cropImg.length - 'data:'+this.file_type+';base64,'.length;
                var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
                var sizeInMb=sizeInBytes/1024/1024;

                if(sizeInMb > 10) {
                    this.modalFileTooBig = true;
                    return;
                }

                this.$emit('croppedImage', this.filename, this.machine_name, this.cropImg, this.indexval);
                this.showCropImagePopup = false;
            },
            myCropFunction: function(e) {
                let croppedCanvas = this.$refs.cropper.getCroppedCanvas();
                if(this.hasAspectRatio && (croppedCanvas.width < this.minCropWidth || croppedCanvas.height < this.minCropHeight)) {
                    this.invalidCrop = true;
                    this.cropDimensionWidth = croppedCanvas.width;
                    this.cropDimensionHeight = croppedCanvas.height;
                } else {
                    this.invalidCrop = false;
                }

                if(!this.hasAspectRatio) {
                    let minAspectRatio = this.minCropHeight / this.minCropWidth;
                    let maxAspectRatio = this.maxCropHeight / this.minCropWidth;
                    let calculatedAspectRatio = croppedCanvas.height / croppedCanvas.width;
                    if(calculatedAspectRatio < minAspectRatio || calculatedAspectRatio > maxAspectRatio) {
                        this.invalidCropAspectRatio = true;
                        if(calculatedAspectRatio < minAspectRatio) {
                            this.invalidCropAspectRatioMessage = 'Crop selected is too large in width.';
                        } else {
                            this.invalidCropAspectRatioMessage = 'Crop selected is too narrow in width.';
                        }
                    } else if(croppedCanvas.width < this.minCropWidth || croppedCanvas.height < this.minCropHeight) {
                        this.invalidCrop = true;
                        this.cropDimensionWidth = croppedCanvas.width;
                        this.cropDimensionHeight = croppedCanvas.height;
                    } else {
                        this.invalidCrop = false;
                        this.invalidCropAspectRatio = false;
                    }
                }
            },
            closeModal: function() {
                this.cropImg = this.initval;
                this.showCropImagePopup = false;
            },
            setImage(e) {
                let that = this;
                const file = e.target.files[0];
                if (file.type.indexOf('image/') === -1) {
                    alert('Please select an image file');
                    return;
                }
                if (that.fileSizeRestriction && file.size > 400000) {
                    that.modalProblemFileSize = true;
                    return;
                }
                that.filename = file.name;
                that.file_type = file.type;

                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        // This section confirms the file dimensions are the minimum acceptable.
                        // If acceptable it initializes the cropper for use otherwise it gives them an alert
                        var image = new Image();
                        image.src = event.target.result;
                        image.onload = function () {
                            var height = this.height;
                            var width = this.width;
                            if (height < that.minCropHeight || width < that.minCropWidth) {
                                that.modalProblemUploading = true;
                                return false;
                            } else if (height == that.minCropHeight && width == that.minCropWidth) {
                                that.cropImg = event.target.result;
                                that.$emit('croppedImage', that.filename, that.machine_name, event.target.result, that.indexval);
                            } else {
                                console.log('last condition met');
                                that.cropImg = event.target.result;
                                if (that.file_type !== 'image/gif') {
                                    that.showCropImagePopup = true;
                                    // rebuild cropperjs with the updated source
                                    that.$refs.cropper.replace(event.target.result);
                                } else {
                                    this.showCropImagePopup = false;
                                    that.$emit('croppedImage', that.filename, that.machine_name, event.target.result, that.indexval);
                                }
                            }
                        };
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },
            crop_or_uploadNewFile: function() {
                if(this.cropImg) {
                    this.uploadFileClick();
                } else {
                    this.showCropImagePopup = false;
                }
            }
        }
    }
</script>
