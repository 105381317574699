<template>

    <div class="col-12" v-if="loadingComplete">
        <div v-if="errors">
            {{ errors }}
        </div>
        <form>

            <div class="row">
                <div class="form-search-wrap col-12">
                    <div class="choices-filter-group double">
                        <input type="text" name="keyword" id="keyword" placeholder="Enter a keyword, order number, program name, or network name" v-model="query_keyword" >
                    </div>
                </div>

                <div class="col-12 pt-2 pull-right ">
                    <a class="pull-right clear-button no-border cursor-pointer" v-on:click.stop="clearSearch()"><close-icon class="svg-16"></close-icon> Clear Search</a>
                </div>
            </div>

        </form>

        <div class="entries-table-wrap">
            <table id="sortable-table" class="table-with-full-hover nice-table">
                <thead>

                <tr class="tableHeaderRow">
                    <th style="width: 145px;" class="cursor-pointer th-entry pl-2" v-on:click="setSort('order_id')">Order # <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_id'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 235px;" class="cursor-pointer th-category" v-on:click="setSort('category')">Category <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'category'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th class="cursor-pointer th-program" v-on:click="setSort('program')">Program <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'program'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th class="cursor-pointer th-network" v-on:click="setSort('network')">Network <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'network'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th class="cursor-pointer th-type" v-on:click="setSort('order_type')">Type <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_type'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 130px;" class="cursor-pointer th-status" v-on:click="setSort('order_status')">
                        Status <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_status'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                </tr>
                </thead>
                <tbody v-if="orders.data && orders.data.length > 0">
                    <tr @click="loadOrder(order.order_id)" class="cursor-pointer" v-for="(order,i) in orders.data">
                        <td  class="pl-2"><div>{{ order.order_id }}</div></td>
                        <td><div>{{ order.category }}</div></td>
                        <td><div>{{ order.program }}</div></td>
                        <td><div>{{ order.network }}</div></td>
                        <td><div>{{ order.order_type }}</div></td>
                        <td class="status">
                            <div class="">
                                {{ order_status_options.find(x => x.id === order.order_status).text }}
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else>
                <tr>
                    <td colspan="6" class="pl-2">No Viewing Platform Orders found. <span v-show="query_keyword !== ''">Try expanding your search query.</span></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="float:right;">
            <pagination :data="orders" @pagination-change-page="paginationCalculator" :limit="3">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        <div class="dataTables_info" v-if="to">Showing 1-{{to}} of {{ total }} orders</div>
        <div class="dataTables_info" v-else>Showing 0 orders</div>
        <div class="dataTables_length admin-entry-table">
            <label>
                <span class="pr-2">Show</span>
                <select class="plain-select num-entries-select" v-model="num">
                    <option value="10">10 Orders</option>
                    <option value="20">20 Orders</option>
                    <option value="50">50 Orders</option>
                    <option value="100">100 Orders</option>
                </select>
                <span>per page</span>
            </label>
        </div>

    </div>
</template>

<script>
import CloseIcon from "@/Icons/close.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";
import UpChevronIcon from "@/Icons/up-chevron.vue";
    export default {
        name: "OrdersTableSearch",
        components: {UpChevronIcon, DownChevronIcon, CloseIcon},
        data() {
            return {
                loadingComplete: false,
                errors: false,
                num:20,
                page:1,
                total: 0,
                to: 0,
                sort:'',
                sort_order: 'desc',
                orders: {},
                query_keyword: (localStorage.getItem('fyc-history-search') != null) ? localStorage.getItem('fyc-history-search') : '',
                query_order_status: '',
                query_order_type: '',
                query_file_status: '',
                order_status_options: [{
                    text: "In Progress",
                    id: "in_progress"
                }, {
                    text: "Submitted",
                    id: "submitted"
                }, {
                    text: "Canceled",
                    id: "canceled"
                }, {
                    text: "Need More Information",
                    id: "need_more_info"
                },
                {
                    text: "User Reported",
                    id: "user_reported"
                },
                {
                    text: "Approved",
                    id: "approved_for_build"
                }, {
                    text: "Approved",
                    id: "approved"
                }, {
                    text: "Ineligible",
                    id: "ineligible"
                }, {
                    text: "Live",
                    id: "live"
                }, {
                    text: "Live - Checked",
                    id: "live_checked"
                }],
                order_type_options: [{
                    'text': 'Viewing Platform',
                    'id': 'Viewing Platform'
                }, {
                    'text': 'Network',
                    'id': 'Network'
                }],
                file_status_options: [    'Uploading',
                    'Upload Failed',
                    'Upload Successful',
                    'Closed Caption File Submitted',
                    'Processing',
                    'Processing Failed',
                    'Under Review',
                    'Need More Info',
                    'Video Approved',
                    'Canceled',
                    'Deleted'],
            }
        },
        mounted() {
            if(localStorage.getItem('fyc-history-search-sort')) {
                this.sort = localStorage.getItem('fyc-history-search-sort');
            }
            if(localStorage.getItem('fyc-history-search-sort-order')) {
                this.sort_order = localStorage.getItem('fyc-history-search-sort-order');
            }
            if(localStorage.getItem('fyc-history-search-num')) {
                this.num = localStorage.getItem('fyc-history-search-num');
            }
            if(localStorage.getItem('fyc-history-search-page')) {
                this.page = localStorage.getItem('fyc-history-search-page');
            }
            if(localStorage.getItem('fyc-history-search-status')) {
                var searchStatus = localStorage.getItem('fyc-history-search-status');
                this.query_order_status = searchStatus.split(',');
            }
            if(localStorage.getItem('fyc-history-search-filestatus')) {
                var searchFileStatus = localStorage.getItem('fyc-history-search-filestatus');
                this.query_file_status = searchFileStatus.split(',');
            }
            if(localStorage.getItem('fyc-history-search-type')) {
                this.query_order_type = localStorage.getItem('fyc-history-search-type');
            }
            this.fetchOrders(this.page);
        },
        methods:{
            loadOrder(order_id){
                window.location = '/order/'+order_id;
            },
            fetchOrders(page = 1){
                let app = this;
                axios.get('/api/orders', {
                    params: {
                        num: app.num,
                        page: page,
                        sort: app.sort,
                        sort_order: app.sort_order,
                        keyword: app.query_keyword,
                        order_status: app.query_order_status,
                        file_status: app.query_file_status,
                        order_type: app.query_order_type
                        }
                    })
                    .then(res => {
                        app.loadingComplete = true;
                        app.orders = res.data;
                        app.total = res.data.total;
                        app.to = res.data.to;
                        localStorage.setItem('fyc-history-search-to', app.to);
                        localStorage.setItem('fyc-history-search', app.query_keyword);
                        localStorage.setItem('fyc-history-search-status', app.query_order_status);
                        localStorage.setItem('fyc-history-search-filestatus', app.query_file_status);
                        localStorage.setItem('fyc-history-search-type', app.query_order_type);
                        localStorage.setItem('fyc-history-search-num', app.num);
                    }).catch(err => {
                    this.errors = 'Error contacting the server : ';
                    this.errors += err;
                    console.log(err)
                });
            },
            setSort(sort) {
                if (this.sort == sort) {
                    this.sort_order = (this.sort_order == 'desc')?'asc':'desc';
                } else {
                    this.sort = sort;
                    this.sort_order = 'desc';
                }
                localStorage.setItem('fyc-history-search-sort', this.sort);
                localStorage.setItem('fyc-history-search-sort-order', this.sort_order);
                this.fetchOrders();
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return '$'+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            openAssignModal(i){
                this.assigned_invoice = this.invoices[i];
                this.check_assign_amount = this.assigned_invoice.amount_due.toFixed(2);
                this.assignCheckModal = true;
            },
            assignCheck() {
                let app = this;
                if (!(app.check_assign_amount > 0)) {
                    alert("Check amount needs to be a value");
                    return;
                }
                app.assignCheckModal = false;
                axios.post('/api/finance/apply_check', {
                    check_assign_amount: app.check_assign_amount,
                    check_assign_number: app.check_assign_number,
                    check_assign_notes: app.check_assign_notes,
                    invoice_id: app.assigned_invoice.id,
                })
                    .then(res => {
                        this.errors = res.data;
                        window.location.reload();
                    }).catch(err => {
                    this.errors = 'Error contacting the server : ';
                    this.errors += err;
                    console.log(err)
                    window.location.reload();
                });

            },
            clearSearch: function() {
                this.query_keyword = '';
                this.sort = '';
                this.sort_order = 'desc';
                this.num = 20;
                this.query_order_status = '';
                this.query_file_status = '';
                this.query_order_type = '';
                localStorage.removeItem('fyc-history-search');
                localStorage.removeItem('fyc-history-search-status');
                localStorage.removeItem('fyc-history-search-filestatus');
                localStorage.removeItem('fyc-history-search-type');
                localStorage.removeItem('fyc-history-search-sort');
                localStorage.removeItem('fyc-history-search-sort-order');
                localStorage.removeItem('fyc-history-search-num');
                localStorage.removeItem('fyc-history-search-page');
            },
            paginationCalculator: function(pageIndex) {
                localStorage.setItem('fyc-history-search-page', pageIndex);
                this.fetchOrders(pageIndex);
            }
        },
        watch: {
            query_keyword() {
                // clear the pagination when the keyword is changing
                localStorage.removeItem('fyc-history-search-page');
                this.fetchOrders(this.page);
                },
            query_order_status() {
                localStorage.removeItem('fyc-history-search-page');
                this.fetchOrders(this.page);
                },
            query_file_status() {
                localStorage.removeItem('fyc-history-search-page');
                this.fetchOrders(this.page);
            },
            query_order_type() {
                localStorage.removeItem('fyc-history-search-page');
                this.fetchOrders(this.page);
            },
            num() {
                this.page = 1;  // Reset to first page when number of items changed
                this.fetchOrders(this.page);
                },
        }
    }
</script>
