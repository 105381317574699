<template>
    <div>
        <div class="entry-info_item">
            <span class="label small-caps">Submitter Name:</span>
            <span class="value">{{your_name}} - <span class="email lowercase">{{your_email}}</span> - <span><small><a href="#" @click.prevent="changeUserModal()" class="btn btn-white text-action p-1"><i class="fas fa-fw fa-pencil"></i> Change</a>
            <span v-if="!is_admin">/</span><a ref="impersonateLink" :href="`/user/`+user_id+`/impersonate?order_id=` + order_id" v-if="!is_admin" @click.prevent="clearSubmitterCache($event)" class="btn btn-white text-action p-1"><i class="fas fa-fw fa-user-secret"></i> Impersonate</a></small></span></span>
        </div>
        <modal v-if="modalTrigger" @close="modalTrigger = false">
            <h3 slot="header">Reassign the submitter of this order.</h3>
            <div class="content" slot="body">
                <p>Current submitter: {{your_name}} - {{your_email}}</p>
                    <vue-suggestion :items="items"
                                    v-model="item"
                                    :setLabel="setLabel"
                                    :itemTemplate="itemTemplate"
                                    @changed="inputChange"
                                    @selected="itemSelected"></vue-suggestion>
                    <div class="red" v-text="errorval"></div>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn btn-black" @click="modalTrigger = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="applyChange" v-show="item.id">Apply change</button>
            </div>
        </modal>
    </div>
</template>

<script>
    import modal from '../components/Modal.vue';
    import itemTemplate from '../components/ItemTemplate.vue';
    import { VueSuggestion } from 'vue-suggestion';

    export default {
        components: {modal, VueSuggestion},
        props: ['your_name', 'your_email', 'user_id', 'order_id', 'action_url', 'action_url_update', 'is_admin'],
        data() {
            return {
                modalTrigger: false,
                item: {},
                items: [],
                itemTemplate,
                errorval: ''
            }
        },
        methods: {
            changeUserModal: function() {
                this.modalTrigger = true;
            },
            applyChange: function() {
                let that = this;
                window.axios(
                    {
                        method: 'PUT',
                        url: that.action_url_update,
                        params: {
                            user: that.item.id
                        }
                    }).then(function(response) {
                        window.location.reload();
                }).catch(function (error) {
                    // handle error
                    console.log('This is the error returned', error);
                });
            },
            inputChange: function(text) {
                let that = this;
                if(text.length < 3) {
                    return false;
                }
                window.axios(
                    {
                        method: 'GET',
                        url: that.action_url,
                        params: {
                            query: text
                        }
                    }).then(function(response) {
                    // We have the query result. Now we're going to 'filter' it to display with our Vue Suggestion component
                        let dataBack = response.data;
                        if(dataBack) {
                            var users = dataBack.suggestions;
                            that.items = users
                                .filter(item => (new RegExp(text.toLowerCase())).test(item.name.toLowerCase()));
                        }
                    that.errorval = '';
                }).catch(function (error) {
                    // handle error
                    // console.log('This is the error returned', error);
                    that.errorval = 'Sorry, no results found for that search.'
                });
            },
            setLabel (item) {
                return item.name;
            },
            itemSelected: function(item) {
                this.item = item;
            },
            disableButton: function() {
                if(this.item.id) {
                    return false;
                } else {
                    return true;
                }
            },
            clearSubmitterCache: function(ev) {
                localStorage.removeItem('fyc-history-search');
                localStorage.removeItem('fyc-history-search-status');
                localStorage.removeItem('fyc-history-search-filestatus');
                localStorage.removeItem('fyc-history-search-type');
                localStorage.removeItem('fyc-history-search-sort');
                localStorage.removeItem('fyc-history-search-sort-order');
                localStorage.removeItem('fyc-history-search-num');
                localStorage.removeItem('fyc-history-search-page');
                window.location = ev.target.href
            }
        }
    }
</script>

<style>
    .vue-suggestion {
        width: 100%;
        margin: 0 auto;
    }
    .vue-suggestion .vs__input-group .vs__input {
        width: 100%;
        display: block;
        margin: 0 auto;
        padding: 0.5rem 0.7rem;
        font-size: 0.9rem;
        line-height: 1.25;
        color: #464a4c;
        outline: none;
        background-color: #fff;
        background-image: none;
        background-clip: padding-box;
        border: 1px solid #cecece;
        border-radius: 0.25rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    .vue-suggestion .vs__input-group .vs__input:focus {
        border: 1px solid #023d7b;
    }
    .vue-suggestion .vs__list {
        width: 100%;
        text-align: left;
        border: none;
        border-top: none;
        max-height: 400px;
        overflow-y: auto;
        border-bottom: 1px solid #023d7b;
    }
    .vue-suggestion .vs__list .vs__list-item {
        cursor: pointer;
        background-color: #fff;
        padding: 10px;
        border-right: 1px solid #023d7b;
    }
    .vue-suggestion .vs__list .vs__list-item:last-child {
        border-bottom: none;
    }
    .vue-suggestion .vs__list .vs__list-item:hover {
        background-color: #eee !important;
    }
    .lowercase {
        text-transform: lowercase;
    }
</style>
